define(['app', 'siteObj', '$location', 'ePopup', 'queryString', '$window'], function(app, siteObj, $location, ePopup, queryString, $window) {



  /**
   * sessionSettings component
   */
  var SessionSettings = function() {

    var self = this;
    /**
     * the config object abstracts out settings which are most likely to change, also dependencies on siteObj
     */
    var _config = {
      closeAriaLabelText: window.siteObj.props.accessibility && window.siteObj.props.accessibility.closeSettings || 'Close settings',
      settingsButtonSelector: '.js-sessionSettingsButton',
      settingsButtonActiveClass: 'sessionSettings_button-active',
      saveButtonSelector: '.js-sessionSettingsSave',
      settingsShippingDestination: '.js-sessionSettings_shippingDestination',
      notifier: {
        cssClass: 'sessionSettings_notification',
        hiddenCssClass: 'sessionSettings_notification-hidden',
        confirmMessage: siteObj.props.sessionSettings.confirmMessage, //unable to factor out of siteObj due to no parent element
        timeout: 5000
      },
      panelContentURL: siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettings.overlay',
      inputs: {
        shippingCountry: {
          selector: '.sessionSettings_shippingCountrySelect',
          valueAttribute: 'data-country-code'
        },
        countrySite: {
          selector: '.sessionSettings_countrySiteSelect',
          valueAttribute: 'data-subsite-code',
          template: function(data) {
            var selected = (data.selected) ? ' selected' : '';
            return '<option class="sessionSettings_countrySiteOption" data-url="' + data.url + '" data-lang-code="' + data.langCode + '" data-subsite-code="' + data.subsiteCode + '"' + selected + '>' + data.countryName + '</option>\n';
          },
          getUrl: function() {
            return siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettingCountries.session?countrycode=' + self.config.getInputValue(self.config.inputs.shippingCountry);
          }
        },
        currency: {
          selector: '.sessionSettings_currencySelect',
          valueAttribute: 'data-currency-code',
          template: function(data) {
            var selected = (data.selected) ? ' selected' : '';
            return '<option class="sessionSettings_currencyOption" data-currency-symbol="' + data.symbol + '" data-currency-code="' + data.code + '"' + selected + '>' + data.symbol + ' ' + data.code + '</option>\n';
          },
          getUrl: function() {
            return siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettingCurrencies.session?subsitecode=' + self.config.getInputValue(self.config.inputs.countrySite);
          }
        },
      },
      getInputValue: function(input) {
        var result = '';
        var selector = app.element.init(input.selector);
        selector.forEach(function(element) {
          result = element.options[element.selectedIndex].getAttribute(input.valueAttribute).toUpperCase();
        });
        return result;
      },
      getUrlForSave: function(siteCode) {
        return siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettingsSave.session?subsitecode=' + siteCode;
      },
      getPageExistsInSelectedLocale: function () {
        return siteObj.siteURL + siteObj.siteDefaultLocale + '/' + siteObj.currencyType + '/sessionSettingsExists.session?subsitecode=' + self.config.getInputValue(self.config.inputs.countrySite) + '&href=https://' + encodeURIComponent($location.hostname) + encodeURIComponent($location.pathname) + encodeURIComponent($location.search);
      }
    };

    /**
     * boolean to indicate whether the panel is ready to be opened
     */
    var _ready = false;

    /**
     * storage property for the panel content
     */
    var _panelContent = '';

    /**
     * storage property for current region
     */
    var _currentRegion = '';

    /**
     * initialise fetching the panel content
     * @returns {{panelContent: string, init: sessionSettings.init, getPopupContent: sessionSettings.getPopupContent, successCallback: sessionSettings.successCallback, openSettings: sessionSettings.openSettings, activateButton: sessionSettings.activateButton, saveSettings: sessionSettings.saveSettings}}
     */
    var _init = function() {
      self.getPopupContent();
      var qs = queryString.parse($location.search);
      if (qs.countrySelected || qs.settingsSaved) {
        self.showNotification();
      }
      return self;
    };

    /**
     * ajax call to fetch panel content
     */
    var _getPopupContent = function() {
      app.ajax.get({
        url: self.config.panelContentURL,
        success: self.contentLoadSuccess,
        error: self.contentLoadError
      });
    };

    /**
     * on successful ajax call to fetch panel content, store the content and activate the sessionSettings button
     * @param content
     */
    var _contentLoadSuccess = function(content) {
      if (content) {
        self.ready = true;
        self.panelContent = content;
        self.activateButton();
        let virtualContent = document.createElement("div");
        self.panelContent = content;
        virtualContent.innerHTML = self.panelContent;
        self.showShippingDestination(virtualContent);
      }
    };

    /**
     * loading the content failed
     */
    var _contentLoadError = function() {
      console.log('contentLoadError', arguments);
    };

    /**
     * open the settings panel
     */
    var _openSettings = function(e) {
      e.preventDefault();
      if (self.ready) {
        self.epopup = new ePopup(self.panelContent, 'sessionSettings_popup', true, undefined, self.config.closeAriaLabelText);
        self.activateForm();
        app.publish('sessionSettings/open');
        if (self.epopup.elements.content.getElementsByClassName('sessionSettings_countrySiteSelect')[0]) {
          self.currentRegion = self.epopup.elements.content.getElementsByClassName('sessionSettings_countrySiteSelect')[0].options[0].value;
        }
      }
    };

    /**
     * Bind handlers for form elements
     */
    var _activateForm = function() {
      app.element.init(self.config.saveButtonSelector).on('click', self.saveSettings).on('keydown', e => e.key === 'Enter' && self.saveSettings(e));
      app.element.init(self.config.inputs.shippingCountry.selector).on('change', self.shippingCountrySelected);
      app.element.init(self.config.inputs.countrySite.selector).on('change', self.countrySiteSelected);
      app.subscribe('sessionSettings/update-countrySite', self.countrySiteSelected);
    };

    /**
     * init selector update
     * @param input
     */
    var _getSelectUpdate = function(input) {
      app.element.init(self.config.inputs[input].selector).forEach(function(element) {
        element.disabled = true;
      });
      app.ajax.get({
        url: self.config.inputs[input].getUrl(),
        success: function(data) {
          self.updateSelect(input, data);
        }
      });
    };

    var _shippingCountrySelected = function() {
      self.getSelectUpdate('countrySite');
      self.showShippingDestination(self.epopup.elements.content);
    };

    var _countrySiteSelected = function() {
      self.getSelectUpdate('currency');
    };

    /**
     * update an input with new values from data
     * @param {string} input config.input key name
     * @param {object} data json returned from endpoint
     */
    var _updateSelect = function(input, data) {
      var items = JSON.parse(data);
      var select = app.element.init(self.config.inputs[input].selector);
      var output = '';
      for (var i = 0; i < items.length; i++) {
        output += self.config.inputs[input].template(items[i]);
      }
      select.forEach(function(element) {
        element.innerHTML = output;
        if (items.length > 1) { //enable only if more than 1 option
          element.disabled = false;
        }
        app.publish('sessionSettings/update-' + input);
      });
    };

    /**
     * Activate the session settings button
     */
    var _activateButton = function() {
      app.element.init(self.config.settingsButtonSelector)
        .on('click', self.openSettings)
        .on('keydown', e => e.key === 'Enter' && self.openSettings(e))
        .forEach(function () {
          app.element.addClass(self.config.settingsButtonActiveClass);
          app.element.removeAttribute('disabled');
        });
    };

    /**
     * Save the session settings
     */
    var _saveSettings = function() {
      app.ajax.get({
        url: self.config.getPageExistsInSelectedLocale(),
        dataType: 'JSON',
        success: self.useReloadInfo
      });
    };

    var _useReloadInfo = function(info) {
      var obj = JSON.parse(info);
      var siteCode = self.config.getInputValue(self.config.inputs.countrySite)
      if (obj.pageExists) {
        app.ajax.get({
          url: self.config.getUrlForSave(siteCode),
          success: self.reloadPage
        });
      } else {
        var sessionSettings = self.epopup.elements.content.getElementsByClassName('sessionSettings')[0];
        var sessionSettingsReload = self.epopup.elements.content.getElementsByClassName('sessionSettingsReload')[0];
        var reloadOkButton = self.epopup.elements.content.getElementsByClassName('sessionSettingsReload_okButton')[0];
        var reloadCancelButton = self.epopup.elements.content.getElementsByClassName('sessionSettingsReload_cancelButton')[0];
        var regionOptions = self.epopup.elements.content.getElementsByClassName('sessionSettings_countrySiteSelect')[0];
        var selectedRegion = regionOptions.options[regionOptions.selectedIndex].value;

        sessionSettings.classList.remove('show');
        sessionSettingsReload.classList.add('show');
        reloadOkButton.innerText += ' ' + selectedRegion;
        reloadCancelButton.innerText += ' ' + self.currentRegion;

        reloadOkButton.addEventListener('click', self.reloadOk);
        reloadCancelButton.addEventListener('click', self.reloadCancel);
        self.epopup.show();
        console.log("Subsite code: " + siteObj.subsiteCode);
        console.log("Site code: " + siteObj.siteCode);
      }
    };

    var _reloadOk = function() {
      var siteCode = self.config.getInputValue(self.config.inputs.countrySite);
      self.epopup.close();
      app.ajax.get({
        url: self.config.getUrlForSave(siteCode),
        success: self.reloadPage
      });
    }

    var _reloadCancel = function() {
      self.epopup.close();
      //self.reloadPage();
    }

    /**
     * reload the page so that filter can perform redirect if required
     * 1. remove the countrySelected parameter - if present it will stop the redirect if a different subsite has been chosen
     */
    var _reloadPage = function() {
      app.publish('sessionSettings/save');
      var parsed = queryString.parse($location.search);
      parsed.shippingcountry = self.config.getInputValue(self.config.inputs.shippingCountry);
      parsed.switchcurrency = self.config.getInputValue(self.config.inputs.currency);
      delete parsed.countrySelected; // 1
      parsed.settingsSaved = 'Y';
      $location.search = queryString.stringify(parsed);
    };

    var _showShippingDestination = function(content) {
      if (content.querySelector(self.config.inputs.shippingCountry.selector)) {
        let country = content.querySelector(self.config.inputs.shippingCountry.selector).options[0].getAttribute(self.config.inputs.shippingCountry.valueAttribute);
        let shippingDestinationPlaceholders = document.querySelectorAll(self.config.settingsShippingDestination);
        Array.from(shippingDestinationPlaceholders).map(el => el.innerHTML = country);
      }
    };

    /**
     * show the settings saved notification if the countrySelected parameter is set
     */
    var _showNotification = function() {
      // create notifier element as a property of this component
      self.notifier = document.createElement('div');
      self.notifier.classList.add(self.config.notifier.cssClass);
      self.notifier.innerText = self.config.notifier.confirmMessage;
      document.body.appendChild(self.notifier);
      // set to dismiss after timeout or on click
      self.notifier.onclick = self.dismissNotification;
      $window.setTimeout(self.dismissNotification, self.config.notifier.timeout);
    };

    /**
     * add the hidden class
     */
    var _dismissNotification = function() {
      self.notifier.classList.add(self.config.notifier.hiddenCssClass);
    };

    self.config = _config;
    self.ready = _ready;
    self.notifier = false;
    self.panelContent = _panelContent;
    self.currentRegion = _currentRegion;
    self.init = _init;
    self.getPopupContent = _getPopupContent;
    self.contentLoadSuccess = _contentLoadSuccess;
    self.showShippingDestination = _showShippingDestination;
    self.contentLoadError = _contentLoadError;
    self.openSettings = _openSettings;
    self.activateForm = _activateForm;
    self.getSelectUpdate = _getSelectUpdate;
    self.shippingCountrySelected = _shippingCountrySelected;
    self.countrySiteSelected = _countrySiteSelected;
    self.updateSelect = _updateSelect;
    self.activateButton = _activateButton;
    self.saveSettings = _saveSettings;
    self.reloadPage = _reloadPage;
    self.showNotification = _showNotification;
    self.dismissNotification = _dismissNotification;
    self.useReloadInfo = _useReloadInfo;
    self.reloadOk = _reloadOk;
    self.reloadCancel = _reloadCancel;
  };

  return new SessionSettings().init();

});
